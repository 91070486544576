.container {
    
  /* background-image: url('../../../public/images/learning-background.jpg');
  background-size: cover; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../../../../public/images/learning-background.jpg') center/cover;
  opacity: 0.3; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}


.loginBox {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  width: 40%;
  min-width: 300px;
  border-radius: 8px;
  text-align: center;
}

.registerLink {
  margin-top: 10px;
}

/* Add any additional styles as needed */
