
.addNewMember{
    position: absolute;
    right:30px;
    top:137px;
    display: inline;
    margin-left: 5px;
}

.addNewMemberLink{
    color: #1890FF;
    text-decoration: underline;
}