

.left-side-menu{
   margin-top: 0px;
}

.logos{
   width: auto;
   height: 60px;
   font-style: italic;
   background-color: #192C3E;
   color: white;
   font-weight: bold;
   display: flex;
   justify-content: center;
   align-items: center;
}

.layoutHeader{
   display: flex;
   justify-content: flex-end;
}

.userName{
   padding-left: 10px;
   color:white;
}

.dropDownMenuText{
   margin-left: 10px;
}


