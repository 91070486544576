.examName {
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    border:solid 1px lightgray;
}

.questionItem{
    background-color: white;
    padding: 20px;
    margin: 20px 0px;
}