.mainTitle{
    font-size: 18px;
    font-weight: 600;
    color: #2C3539;
    text-transform: uppercase;
}

.totalShiftNumber{
    font-weight: bold;
}

.totalValue{
    font-weight: bold;
}

.main-content {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.task-button {
    margin-left: 5px;
    margin-top: 5px;
}

.getText-button {
    margin-left: 5px;
}

.table-container {
    overflow: scroll;
}

.main-table {
    min-width: 1000px;
}


.showDetailCheckbox{
    margin-right: 5px !important;
}

.smallNoteText{
    color: #3B3C36;
}

.copyTextSetting{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warningRow{
    background-color: orange;
}


