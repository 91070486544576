.formBox{
    border: 1px solid rgba(128, 128, 128, 0.3);
    padding: 20px 20px;
    position: relative;
}

.formBoxTitle{
    position: absolute;
    left:30px;
    top:-10px;
    background-color: white;
    padding: 0 5px;
}