.actionButtons{
    margin-left: auto;
}

.itemHeader{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.questionTitle{
    font-weight: bold;
    font-size: 16px;
}

.dividerBox{
    margin-top: 30px;
}