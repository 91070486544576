.timeTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timeTableItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 300px; /* Adjust the width as needed */
  }
  
  .selectContainer {
    width: 45%; /* Adjust the width as needed */
  }
  